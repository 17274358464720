.App {
  color: #1b4552;
  font-size: calc(10px + 1.5vmin);
}

.name {
  color: #d74a49;
}

/* 
  All non-phone screens 
  - Need to tweak screen sizes below destop + and phone

*/

@media (min-width: 431px) {
  .App {
    margin-left: 30px;    
  }

  h2 {
    margin: 2% 2% 2% 0;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .about {
    width: 725px;
    
  }

  .about a {
    font-weight: bold;
    display: inline;
  }

  .profiles {
    display: flex;
    margin: 1% 0 1% 0;
  }

  .linkedin-icon {
    width: 50px;
    padding-right: 10px;
  }

  .github-icon {
    width: 47px;
  }

  /* Project Section */
  .projects-section {
    width: 725px;
  }

  .projects-section-header {
    font-size: 1.5rem;
    margin-bottom: 1.5%;
    font-weight: 500;
  }

  .project {
    margin-bottom: 1.75%;
  }

  .project-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: .5;
  }
  
  .project-title {
    font-weight: 600;
  }

  .project-details {
    width: 150px;  
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
  }

  .project-description {
    margin-left: 3.8%;
    font-size: 1.1rem;
  }

}

/* Mobile formatting */
@media (max-width: 430px) {
  .App {
    max-width: 95%;
    margin: 2.5%;
  }

  h2 {
    margin: 2% 2% 2% 0;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .about a {
    font-weight: bold;
    display: inline;
  }

  .profile-icon {
    width: 15%;
    padding-right: 1%;
  }

  .profiles {
    display: flex;
    margin-bottom: 2.5%;
  }

  .linkedin-icon {
    width: 50px;
    padding-right: 10px;
  }

  .github-icon {
    width: 47px;
  }

  /* Project Section */
  .projects-section {
    
  }

  .projects-section-header {
    font-size: 20px;
    margin-bottom: 1.5%;
    font-weight: 500;
  }

  .project {
    margin-bottom: 10%;
  }

  .project-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: .5;
  }
  
  .project-title {
    font-weight: 600;
  }

  .project-details {
    width: 125px;  
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
  }

  .project-description {
    font-size: 16px;
    margin-left: 3.8%;
    
  }


}